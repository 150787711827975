
import ApiService from "@/services/api.service";
import { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { ClientResponse } from "../Client.vue";
import VueNextSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'
import { formatMoney } from '@/utils/Helpers'

export interface Return {
  Ref: string
  statut: string
  client_name: string
  GrandTotal: number
  paid_amount: number
  due: number
  payment_status: string
}
export interface ClientReturn {
  returns_customer: Return[]
  totalRows: number
}
export default defineComponent({
  name: 'Return',
  components: {
    VueNextSelect
  },
  setup() {
    const selectedClient = ref('')
    const clients = ref<ClientResponse>({
      clients: [],
      totalRows: 0
    })
    const clientReturns = ref<ClientReturn>({
      returns_customer: [],
      totalRows: 0
    })
    const getReturns = async() => {
      try {
        const { data }: AxiosResponse<ClientReturn> = await ApiService.get('/report/client_returns', {
          params: {
            limit: 100,
            id: selectedClient.value
          }
        })
        clientReturns.value = data
      } catch (error) {
        console.log(error);
        
      }
    }
    const getClients = async () => {
      try {
        const { data }: AxiosResponse<ClientResponse> = await ApiService.get('/clients/clients', {
          params: {
            limit: 100,
            search: '',
            SortType: 'asc',
            SortField: 'name',
            page: 1
          }
        })
        clients.value = data
      } catch (error) {
        console.log(error);
      }
    }
    onMounted(() => {
      getClients()
    })
    return {
      clientReturns,
      selectedClient,
      clients,
      getReturns,
      formatMoney
    }
  }
})
